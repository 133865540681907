import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import {
  Feature,
  GeocodingResponse,
  StructuredGeocodingParams,
} from '../interfaces/mapbox-geocoding.interface';
import {
  MapboxFeatureType,
  MapboxSuggestion,
  MapboxSuggestParams,
  MapboxSuggestResponse,
} from '../interfaces/mapbox-suggest.interface';

// Environment
import { LabgoEnvironment } from '../models/labgo-environment.interface';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  private readonly _mapboxUrl: string = this._env.mapbox.mapboxUrl;
  private readonly _searchBoxApi: string = this._env.mapbox.searchBoxApi;
  private readonly _searchGeocodeApi: string =
    this._env.mapbox.searchGeocodeApi;
  private readonly token: string = this._env.production
    ? this._env.mapbox.key
    : this._env.mapbox.publicKey || '';

  private readonly _http: HttpClient = inject(HttpClient);

  constructor(@Inject('env') private readonly _env: LabgoEnvironment) {}

  getForwardGeocodingWithStructuredInputPlace(
    place: string,
    lang: string = 'en'
  ): Observable<Feature[]> {
    const mapboxParams: StructuredGeocodingParams = {
      access_token: this.token,
      language: lang,
      place,
      limit: 3,
    };

    const params = new HttpParams({
      fromObject: mapboxParams as any,
    });

    return this._http
      .get<GeocodingResponse>(
        `${this._mapboxUrl}/${this._searchGeocodeApi}/forward`,
        { params }
      )
      .pipe(map((response: GeocodingResponse) => response.features));
  }
}
