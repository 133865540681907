// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  debug: true,
  server: {
    url: 'https://api-dev.lab-go.com/api/v1',
  },
  keycloak: {
    realm: 'app',
    url: 'https://auth-dev.lab-go.com',
    clientId: 'ng-pwa-dev',
  },
  mapbox: {
    mapboxUrl: 'https://api.mapbox.com',
    searchBoxApi: 'search/searchbox/v1',
    searchGeocodeApi: 'search/geocode/v6',
    key: 'pk.eyJ1Ijoibmljb2xhc3NwYWdsaWFyZGkiLCJhIjoiY20zaGh2c3M1MGI2aDJwc2FwZWJmeGZ3aSJ9.ny-tGE99bA79J_HrLyvcsA',
  },
  auth: {
    authConfig: {
      issuerIDServer: 'https://portal-dev.lab-go.com/api',
      issuer: 'http://localhost:8080',
      clientIdIDServer: 'Ermit.Client.Pwa',
      clientId: 'ng-client-test',
      responseType: 'code',
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      scope: 'openid profile email offline_access pwa',
      useSilentRefresh: false,
      silentRefreshTimeout: 500,
      timeoutFactor: 0.1,
      sessionChecksEnabled: false,
      showDebugInformation: true,
      clearHashAfterLogin: false,
      nonceStateSeparator: 'semicolon',
    },
    authModuleConfig: {
      resourceServer: {
        allowedUrls: ['https://api-dev.lab-go.com/api/v1'],
        sendAccessToken: true,
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
