import moment from 'moment';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ageValidator(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formValue = control.value;
    const { year, month, day } = formValue;

    if (!year || !month || !day) {
      return null;
    }

    const birthDate = moment({ year, month: month - 1, day });
    const today = moment();
    const age = today.diff(birthDate, 'years');

    if (age >= minAge) {
      return null;
    } else {
      return { ageInvalid: true };
    }
  };
}
