import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class PWAAuthService {

  constructor(protected keycloak: KeycloakService) {}

  get sessionToken(): string {
    return this.keycloak.getKeycloakInstance().sessionId || '';
  }

  isAutenticated(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }

  logout(redirectUrl?: string): Promise<void> {
    if (redirectUrl) {
      const url = window.location.origin + redirectUrl;
      return this.keycloak.logout(url);
    }
    return this.keycloak.logout();
  }

  isLoggedIn(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }

  async getJwtToken(): Promise<{ token: string; decodedToken: any }> {
    try {
      const token = await this.keycloak.getToken();
      const decodedToken = jwt_decode.default(token);
      return { token, decodedToken };
    } catch (errore) {
      console.error(
        'Errore durante il recupero o la decodifica del token JWT:',
        errore
      );
      throw errore;
    }
  }
}
